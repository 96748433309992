<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/grant' }">
        <app-i18n code="entities.grant.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.grant.view.title"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.grant.view.title"></app-i18n>
      </h1>

      <div
        class="app-page-spinner"
        v-if="loading"
        v-loading="loading"
      ></div>

      <app-grant-view-toolbar v-if="record"></app-grant-view-toolbar>

      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        @submit.prevent.native
        class="form"
        v-if="record"
      >
        <app-view-item-relation-to-one
          :label="fields.fundingAgency.label"
          :readPermission="fields.fundingAgency.readPermission"
          :navigatePermission="
            fields.fundingAgency.navigatePermission
          "
          :url="fields.fundingAgency.viewUrl"
          :value="presenter(record, 'fundingAgency')"
        ></app-view-item-relation-to-one>

        <app-view-item-text
          :label="fields.contactPerson.label"
          :value="presenter(record, 'contactPerson')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.tenderUrl.label"
          :value="presenter(record, 'tenderUrl')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.deadlineForSubmission.label"
          :value="presenter(record, 'deadlineForSubmission')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.availabilityOfGrantorDecision.label"
          :value="presenter(record, 'availabilityOfGrantorDecision')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.projectDuration.label"
          :value="presenter(record, 'projectDuration')"
        ></app-view-item-text>

        <app-view-item-file
          :label="fields.decisionFile.label"
          :value="presenter(record, 'decisionFile')"
          :permissions="fields.decisionFile.permissions"
        ></app-view-item-file>

        <app-view-item-text
          :label="fields.createdAt.label"
          :value="presenter(record, 'createdAt')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.updatedAt.label"
          :value="presenter(record, 'updatedAt')"
        ></app-view-item-text>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GrantViewToolbar from '@/modules/grant/components/grant-view-toolbar.vue';
import { GrantModel } from '@/modules/grant/grant-model';

const { fields } = GrantModel;

export default {
  name: 'app-grant-view-page',

  props: ['id'],

  components: {
    [GrantViewToolbar.name]: GrantViewToolbar,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'grant/view/record',
      loading: 'grant/view/loading',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'grant/view/doFind',
    }),

    presenter(record, fieldName) {
      return GrantModel.presenter(record, fieldName);
    },
  },
};
</script>
